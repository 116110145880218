<template>
  <div class="section">
    <p class="title">{{ $t('hotel.qr_title') }}</p>
    <p class="section__text">{{ $t('hotel.qr_text') }}</p>
    <div class="section__content">
      <div class="section__left">
        <div v-for="n in 6" :key="n" class="section__left-item">
          <div class="section__left-item__icon"></div>
          <div class="section__left-item__content">
            <p class="section__left-item__title">{{ $t(`hotel.qr_item_${ n }_title`) }}</p>
            <p v-html="$t(`hotel.qr_item_${ n }_text`)" class="section__left-item__text"></p>
          </div>
          <div v-if="n === 4" class="section__right pointer" @click="redirectToTg">
            <p class="title">{{ $t('client.features_item_10_title') }}</p>
            <p class="section__right-text">{{ $t('client.features_item_10_text') }}</p>
            <div class="section__right-icon"></div>
          </div>
        </div>
      </div>
      <div class="section__right pointer" @click="redirectToTg">
        <p class="title">{{ $t('client.features_item_10_title') }}</p>
        <p class="section__left-item__text">{{ $t('client.features_item_10_text') }}</p>
        <div class="section__right-icon"></div>
      </div>
    </div>
    <p class="section__hint">{{ $t('hotel.qr_hint') }}</p>
  </div>
</template>

<script setup lang="ts">
import { useButton } from "@/composable/useButton";

const { redirectToTg } = useButton();
</script>

<style scoped lang="scss">
.section {
  &__left {
    @extend .flex-col;

    &-item {
      @extend .row-container;

      &__content {
        @extend .flex-col;
      }
    }
  }
}

.desktop .section {
  margin: 6vw 0 2vw;

  & > .title {
    width: 80%;
    margin-bottom: 1.05vw;
  }

  &__text {
    width: 88%;
    margin-bottom: 3.3vw;
    @include font-20();
  }

  &__content {
    @extend .flex-row;
    justify-content: space-between;
    gap: 3.5vw;
    margin-bottom: 4vw;
  }

  &__left {
    gap: 2.38vw;
    width: 47.55vw;

    &-item {
      gap: 2.18vw;

      .section__right {
        display: none;
      }

      &__icon {
        @include square(3.3vw);
        background: $primary url("@/assets/images/hotel-qr.png") no-repeat;
        background-size: contain;
        border-radius: 10px;
      }

      &__content {
        gap: 1.05vw;
      }

      &__title {
        @include font-28($weight: 600);
      }

      &__text {
        @include font-28();
      }
    }
  }

  &__right {
    position: relative;
    top: 26.7vw;
    width: 32vw;
    height: max-content;

    .title {
      margin-bottom: 2.38vw;
    }

    &-icon {
      position: absolute;
      margin-top: 3.04vw;
      right: calc(-9.29vw - 4vw);
      @include icon($click, 33.07vw);
      transform: rotate(-0.25turn);
    }

    &:before {
      position: absolute;
      top: -15.2vw;
      left: -10.45vw;
      @include before(68.4vw, 77vw);
      @include svgBg($click-bg);
      background-position-x: 0;
      z-index: -1;
    }
  }

  &__hint {
    @include font-18($height: 128%, $color: $grey, $weight: 400);
  }
}

.mobile .section {
  margin: 12vw 0 22.05vw;

  & > .title {
    width: 80%;
    margin-bottom: 4.1vw;
  }

  &__text {
    margin-bottom: 10.25vw;
    @include font-16();
  }

  &__left {
    gap: 6.15vw;

    &-item {
      &:nth-child(4) {
        position: relative;

        .section__left-item__content {
          width: 59vw;
        }
      }

      &__icon {
        display: none;
      }

      &__content {
        gap: 2.05vw;
      }

      &__title {
        @include font-20($weight: 600, $height: 120%);
      }

      &__text {
        @include font-18();
      }
    }
  }

  &__right {
    position: absolute;
    top: 6.15vw;
    right: 0;
    width: 33.58vw;
    margin-right: -4.1vw;

    .title {
      margin-bottom: 2.05vw;
      @include font-24($height: 120%, $weight: 600);
    }

    &-icon {
      position: absolute;
      top: 55vw;
      right: calc(-9.5vw);
      @include icon($click, 33.3vw);
      transform: rotate(-0.25turn);
    }

    &-text {
      @include font(2.05vw, 120%, $text, 500);
    }

    &:before {
      position: absolute;
      top: -6.15vw;
      left: -27.17vw;
      @include before(92.8vw, 100vw);
      @include svgBg($click-bg);
      z-index: -1;
    }
  }

  &__left + .section__right {
    display: none;
  }

  &__hint {
    margin-top: 6.15vw;
    @include font-14($color: $grey, $weight: 400);
  }
}
</style>
