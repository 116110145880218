<template>
  <div class="panel">
    <panel-header />
    <div class="panel__content">
      <div class="panel__content-left">
        <p class="panel__content-left__title">{{ $t('contacts.main_title_header') }}</p>
        <p class="panel__content-left__text">{{ $t('contacts.main_title_content') }}</p>
      </div>
      <div class="panel__content-right">
        <div class="panel__content-contact" @click="redirectToEmail">
          <p class="panel__content-contact__title">{{ $t('contacts.contact_icon_email') }}</p>
          <p class="panel__content-contact__text">sales@smarthotelservice.com</p>
          <div class="panel__content-contact__icon email"></div>
        </div>

        <div class="panel__content-contact" @click="redirectToTg">
          <p class="panel__content-contact__title">{{ $t('contacts.contact_icon_telegram') }}</p>
          <div class="panel__content-contact__icon telegram"></div>
        </div>

        <div class="panel__content-contact" @click="copyText('+380960501834')">
          <p class="panel__content-contact__title">{{ $t('contacts.contact_icon_phone') }}</p>
          <p class="panel__content-contact__text">+380 96 050 18 34</p>
          <div class="panel__content-contact__icon phone"></div>
        </div>
      </div>
    </div>

    <link-button class="top right" :text="$t('buttons.free_month')" @click="redirectToTg" />
    <link-button class="bottom left" :text="$t('buttons.our_services')" @click="goToHotelPage" />
  </div>
</template>

<script setup lang="ts">
import LinkButton from "@/components/Button/LinkButton.vue";
import PanelHeader from "@/components/Panel/PanelHeader.vue";
import { useButton } from "@/composable/useButton";

const { redirectToTg, goToHotelPage, copyText, redirectToEmail } = useButton();
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/main";

.panel {
  &__content {
    &-left {
      @extend .flex-col;
    }

    &-contact {
      @extend .col-container, .pointer;
      justify-content: center;
      background-color: white;

      &__icon {
        &.telegram {
          @include svgBg($telegram);
        }

        &.email {
          @include svgBg($email);
        }

        &.phone {
          @include svgBg($phone);
        }
      }
    }
  }
}

.desktop .panel {
  margin-bottom: 7.27vw;

  &__content {
    @extend .flex-row;
    justify-content: space-between;

    &-left {
      gap: 3.04vw;
      width: 32.5vw;
      margin-top: 7.2vw;
      margin-left: 2.38vw;

      &__title {
        @include font(4vw, 120%, $text, 600);
      }

      &__text {
        @include font-24();
      }
    }

    &-right {
      @extend .row-container;
      justify-content: center;
      flex-wrap: wrap;
      gap: 1.58vw;
      width: calc(23.8vw * 2 + 1.58vw);
      margin: calc(min(3.8vw, 5vh)) 0.75vw 0.75vw 0;
    }

    &-contact {
      gap: 1.05vw;
      width: calc(min(23.8vw));
      height: calc(min(21.4vw, 32vh));
      padding: 0 2vw;
      border-radius: 50px;
      box-sizing: border-box;

      &:first-child {
        min-width: max-content;
        margin: 0 1rem;
      }

      &__title {
        @include font-28($weight: 600);
      }

      &__text {
        @include font-24($align: center);
      }

      &__icon {
        @include square(6.6vw);
      }
    }
  }
}

.mobile .panel {
  margin-bottom: 12.3vw;

  &__content {
    @extend .flex-col;
    margin-top: 18vw;

    &-left {
      margin-bottom: 6.15vw;

      &__title {
        margin-bottom: 4.1vw;
        @include font(9.23vw, 120%, $text, 600);
      }

      &__text {
        @include font-18();
      }
    }

    &-right {
      @extend .flex-col;
      gap: 4.1vw;
      margin-bottom: 17vw;
    }

    &-contact {
      gap: 2.05vw;
      flex: 1;
      min-height: 38vw;
      border-radius: 30px;

      &__title {
        @include font-20($weight: 600);
      }

      &__text {
        @include font-18($align: center);
      }

      &__icon {
        @include square(12.8vw);
      }
    }
  }
}
</style>
