<template>
  <div class="panel">
    <panel-header />
    <link-button class="top right" :text="$t('buttons.order_presentation')" @click="redirectToTg" />
    <link-button class="bottom left" :text="$t('buttons.our_services')" @click="goToHotelPage" />
    <div class="panel__content">
      <div class="panel__left">
        <p class="panel__left-title">{{ $t('home.title_1') }}</p>
        <div class="panel__left-wrapper">
          <div class="panel__left-avatars">
            <img class="panel__left-avatars__item" alt="img" src="@/assets/images/avatar-1.jpg" />
            <img class="panel__left-avatars__item" alt="img" src="@/assets/images/avatar-2.jpg" />
            <img class="panel__left-avatars__item" alt="img" src="@/assets/images/avatar-3.jpg" />
            <img class="panel__left-avatars__item" alt="img" src="@/assets/images/avatar-4.jpg" />
          </div>
          <p class="panel__left-title">{{ $t('home.title_2') }}</p>
        </div>
        <p class="panel__left-title">{{ $t('home.title_3') }}</p>
        <img class="panel__right" src="@/assets/images/home-6.png" alt="img" />
        <p class="panel__left-subtitle">{{ $t('home.subtitle') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import LinkButton from "@/components/Button/LinkButton.vue";
import PanelHeader from "@/components/Panel/PanelHeader.vue";
import { useButton } from "@/composable/useButton";

const { goToHotelPage, redirectToTg } = useButton();
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/main";

.panel {
  &__left {
    position: relative;

    &-title {
      text-transform: uppercase;
    }

    &-wrapper {
      @extend .row-container;
    }

    &-avatars {
      @extend .flex-row;

      &__item {
        @extend .circle;
        object-fit: cover;
      }
    }
  }
}

.desktop .panel {
  &__content {
    @extend .flex-row;
    justify-content: space-between;
    gap: 5.03vw;
    margin-top: 3.24vw;
  }

  &__left {
    max-width: 37vw;
    margin-left: calc(min(4vw, 30px));

    &-title {
      @include font(calc(min(4.63vw, 70px)), 120%, $text, 600);
    }

    &-subtitle {
      margin: 1.58vw 0 7vw;
      @include font-24($height: 140%);
    }

    &-avatars {
      margin-right: 1.32vw;

      &__item {
        @include square(calc(min(5.45vw, 70px)));
        border: 7px solid $primary;

        &:not(:first-child) {
          margin-left: -2vw;
        }
      }
    }
  }

  &__right {
    position: absolute;
    top: -2vw;
    left: calc(100% + 6.21vw);
    width: 42.75vw;
    height: 115%;
    object-fit: contain;
  }
}

.mobile .panel {
  &__content {
    @extend .flex-col;
    margin-top: 17.18vw;
  }

  &__left {
    &-title {
      @include font(10.25vw, 120%, $text, 600);
    }

    &-subtitle {
      margin-bottom: 22vw;
      @include font-18($height: 120%);
    }

    &-avatars {
      margin-right: 2px;

      &__item {
        width: 10.77vw;
        height: 10.771vw;
        border: 4px solid $primary;

        &:not(:first-child) {
          margin-left: -3.5vw;
        }
      }
    }
  }

  &__right {
    width: 100%;
  }
}
</style>
