<template>
  <div class="panel">
    <panel-header />
    <link-button class="top right" :text="$t('buttons.free_month')" @click="redirectToTg" />
    <link-button class="bottom left" :text="$t('buttons.coworking_details')" @click="redirectToTg" />
    <div class="panel__content">
      <div class="panel__left">
        <p class="title">{{ $t('client.main_title') }}</p>
        <p class="panel__text">{{ $t('client.main_text') }}</p>
      </div>
      <img src="@/assets/images/client-1.png" alt="img" class="panel__right" />
    </div>
  </div>
</template>

<script setup lang="ts">
import LinkButton from "@/components/Button/LinkButton.vue";
import PanelHeader from "@/components/Panel/PanelHeader.vue";
import { useButton } from "@/composable/useButton";

const { redirectToTg } = useButton();
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/main";

.panel {
  &__content {
    position: relative;
    width: calc(100% + 1.58vw);
    overflow: hidden;
  }

  &__right {
    position: absolute;
  }
}

.desktop .panel {
  margin-bottom: 8.5vw;
  padding-bottom: 0;

  .title {
    width: 44vw;
    margin-bottom: 1.58vw;
  }

  &__content {
    border-bottom-right-radius: 80px;
  }

  &__left {
    margin: calc(min(7.2vw, 6vh)) 0 10.58vw 2.64vw;
  }

  &__text {
    width: 42.06vw;
    @include font-24();
  }

  &__right {
    top: calc(min(7.2vw, 5vh));
    left: 45.52vw;
    width: 54.6vw;
    height: 95%;
    object-fit: contain;
  }
}

.mobile .panel {
  margin-bottom: 17.7vw;
  padding: 4.1vw 1.53vw;

  .title {
    width: 75%;
    margin-bottom: 72vw;
    text-wrap: balance;
  }

  &__left {
    //margin: 16.15vw 0 13vw 2.25vw;
    margin: 17.5vw 0 13vw 2.25vw;
  }

  &__right {
    top: 43vw;
    left: -5vw;
    width: 107.3vw;
  }

  &__text {
    width: calc(100% - 4.5vw);
    margin-bottom: 16.15vw;
    @include font-18();
  }
}
</style>
