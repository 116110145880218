<template>
  <div class="wrapper">
    <div class="popup">
      <div class="popup__close" @click="$emit('close')"></div>
      <p class="popup__header">{{ $t('drop_menu.header') }}</p>
      <div class="popup__list">
        <p v-for="tab in tabs" :key="tab" class="popup__list-item"
             :class="{'popup__list-item--selected': tab === selectedTab}" @click="push(`/${tab}`)">
          {{ $t(`tabs.${ tab }`) }}
        </p>
      </div>
      <div class="popup__footer">
        <p class="popup__footer-item" @click="push('/privacy')">{{ $t('drop_menu.privacy') }}</p>
        <p class="popup__footer-item" @click="push('/conditions')">{{ $t('drop_menu.condition') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { PropType, } from "vue";

defineProps({
  tabs: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  selectedTab: String
})
defineEmits(['close']);

const { push } = useRouter();
</script>

<style scoped lang="scss">
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  background-color: #{$text}4C;
  z-index: 2000;
}

.popup {
  @extend .flex-col;
  position: absolute;
  width: calc(100% - 4.1vw * 2);
  height: calc(100% - 4.1vw * 2);
  margin: 4.1vw;
  padding: 6.15vw;
  background-color: #FFFFFFD9;
  border-radius: 10px;
  box-sizing: border-box;
  backdrop-filter: blur(2px);

  &__close {
    position: absolute;
    right: 6.15vw;
    @include icon($cross, 9.23vw);
  }

  &__header {
    margin-bottom: 17.17vw;
    @include font-24($height: 9.23vw);
  }

  &__list {
    @extend .flex-col;
    gap: 8.2vw;

    &-item {
      position: relative;
      @include font-28();

      &--selected {
        font-weight: 600;

        &:after {
          @include before(47.43vw, 0);
          position: absolute;
          bottom: -2.05vw;
          border-bottom: 4px solid $primary;
        }
      }
    }
  }

  &__footer {
    @extend .flex-col;
    gap: 6.15vw;
    margin-top: auto;

    &-item {
      @include font-18($align: center);
    }
  }
}
</style>
