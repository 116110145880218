<template>
  <div class="section">
    <p class="title">{{ $t('home.points_title') }}</p>
    <p class="section__text">{{ $t('home.points_text') }}</p>
    <div class="section__list">
      <div v-for="n in 12" :key="n" class="section__list-item">
        <p class="section__list-item__title">{{ $t(`home.points_item_${ n }_title`) }}</p>
        <p class="section__list-item__text">{{ $t(`home.points_item_${ n }_text`) }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.section {
  @extend .flex-col;

  &__list {
    &-item {
      @extend .flex-col;
      background-color: $primary;
      box-sizing: border-box;
    }
  }
}

.desktop .section {
  margin-top: 6.6vw;

  .title {
    margin-bottom: 1.58vw;
  }

  &__text {
    @include font-20($height: 150%);
    margin-bottom: 3.17vw;
  }

  &__list {
    @extend .flex-row;
    flex-wrap: wrap;
    gap: 1.5vw;

    &-item {
      width: calc((100% - 1.5vw * 3) / 4);
      padding: 1.25vw;
      border: 4px dashed $text;
      border-radius: 40px;

      &__title {
        @include font(1.4vw, 140%, $text, 600);
        margin-bottom: 1.05vw;
      }

      &__text {
        @include font(1.4vw, 140%, $text, 500);
      }
    }
  }
}

.mobile .section {
  margin-bottom: 9.2vw;

  .title {
    margin-bottom: 1.58vw;
  }

  &__text {
    @include font-20($height: 150%);
    margin-bottom: 3.17vw;
  }

  &__list {
    @extend .flex-col;
    gap: 4.1vw;

    &-item {
      padding: 4.1vw;
      border: 2px dashed $text;
      border-radius: 20px;

      &:not(:nth-child(1)):not(:nth-child(8)):not(:nth-child(11)) {
        display: none;
      }

      &__title {
        @include font-18($height: 120%);
        margin-bottom: 2.05vw;
      }

      &__text {
        @include font-16();
      }
    }
  }
}
</style>
