<template>
  <div class="section">
    <div class="section__left">
      <p class="title">{{ $t('home.about_title') }}</p>
      <p v-html="$t('home.about_text')" class="section__left-text"></p>
    </div>
    <img src="@/assets/images/home-3.png" alt="img" class="section__right" />
    <img src="@/assets/images/home-5.png" alt="img" class="section__image" />
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
.section {
  position: relative;

  &__left {
    @extend .flex-col;
    z-index: 100;
  }

  &__right {
    position: absolute;
    right: -13vw;
    top: 0;
    width: calc(100dvw + 6vw);
    height: 46.43vw;
    object-fit: contain;
  }
}

.desktop .section {
  @extend .row-container;
  margin: 5.3vw 0 16vw;

  &__left {
    width: 43.58vw;

    .title {
      margin: 3.17vw 0 2.38vw;
    }

    &-text {
      @include font-24();
    }
  }

  &__image {
    display: none;
  }
}

.mobile .section {
  @extend .flex-col;
  position: relative;
  left: -4.1vw;
  width: 100%;
  margin: 17.7vw 0;
  padding: 0 4.1vw;
  background: linear-gradient(to bottom, #{$primary}00, #{$primary}00 45%, $primary);
  border-radius: 8px;

  &__left {
    .title {
      margin: 3.17vw 0 2.38vw;
    }

    &-text {
      @include font-18($height: 120%, $color: $grey);
    }
  }

  &__right {
    display: none;
  }

  &__image {
    width: 110.5vw;
    margin-top: 12vw;
    margin-left: -12vw;
  }
}
</style>
