<template>
  <div class="section">
    <p class="title">{{ $t('client.features_title') }}</p>
    <div class="section__list">
      <div class="section__list-col">
        <div v-for="n in 4" :key="n" class="section__list-item">
          <p class="section__list-item__title">{{ $t(`client.features_item_${ n }_title`) }}</p>
          <p class="section__list-item__text">{{ $t(`client.features_item_${ n }_text`) }}</p>
        </div>
        <div class="section__list-row">
          <div class="section__list-item">
            <p class="section__list-item__title">{{ $t(`client.features_item_4_title`) }}</p>
            <p class="section__list-item__text">{{ $t(`client.features_item_4_text`) }}</p>
          </div>
          <div class="section__list-item pointer" @click="redirectToTg">
            <p class="section__list-item__title">{{ $t(`client.features_item_10_title`) }}</p>
            <p class="section__list-item__text">{{ $t(`client.features_item_10_text`) }}</p>
          </div>
        </div>
      </div>

      <div class="section__list-col">
        <div v-for="n in 4" :key="n + 4" class="section__list-item">
          <p class="section__list-item__title">{{ $t(`client.features_item_${ n + 4 }_title`) }}</p>
          <p class="section__list-item__text">{{ $t(`client.features_item_${ n + 4 }_text`) }}</p>
        </div>
      </div>

      <div class="section__list-col">
        <div v-for="n in 3" :key="n + 8" class="section__list-item" :class="{'pointer': n + 8 === 10}"
             @click="onItemClick(n + 8)">
          <p class="section__list-item__title">{{ $t(`client.features_item_${ n + 8 }_title`) }}</p>
          <p class="section__list-item__text">{{ $t(`client.features_item_${ n + 8 }_text`) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useButton } from "@/composable/useButton";

const { redirectToTg } = useButton();

const onItemClick = (number: number) => {
  if (number === 10) {
    redirectToTg();
  }
};
</script>

<style scoped lang="scss">
.section {
  &__list {
    position: relative;

    &-item {
      position: relative;
      height: max-content;
      padding: 1.58vw;
      background-color: $secondary;
      box-sizing: border-box;

      &__title {
        @include font-28($weight: 600);
        margin-bottom: 1.05vw;
      }

      &__text {
        @include font-24();
      }
    }
  }
}

.desktop .section {
  margin-top: 6vw;
  margin-bottom: 4vw;

  .title {
    margin-bottom: 2.3vw;
  }

  &__list {
    @extend .flex-row;
    gap: 1.58vw;

    &-row {
      display: none;
    }

    &-item {
      @extend .rounded--s;
      padding: 1.58vw;

      &__title {
        @include font-28($weight: 600);
        margin-bottom: 1.05vw;
      }

      &__text {
        @include font-24();
      }
    }

    &-col {
      @extend .flex-col;
      gap: 1.58vw;
      height: auto;

      &:nth-child(1) .section__list-item {
        height: 100%;
        width: 23.15vw;
      }

      &:nth-child(2) .section__list-item {
        &:nth-child(2) {
          width: 30.42vw;

          &:after {
            $size: calc(100vw - 4vw * 2 - 23.15vw - 30.42vw - 1.58vw - 1.05vw - 23vw - 1vw);
            position: absolute;
            top: 0;
            right: calc(-1.05vw - $size);
            @include before($size, calc(100% + 15vw));
            background-color: $primary;
            border-radius: 60px 60px 0 60px;
          }

          &:before {
            position: absolute;
            top: calc(100% - 5vw + 1.58vw);
            right: -1.05vw;
            @include before(5vw, 5vw);
            background-color: transparent;
            border-radius: 50px;
            box-shadow: 2vw 2vw 0 $primary;
          }
        }

        &:nth-child(3) {
          width: 23vw;

          &:after {
            $size: calc(100vw - 23.15vw - 1.58vw * 2 - 23vw - 4vw * 2);
            position: absolute;
            top: 0;
            right: calc(-1.58vw - #{$size});
            @include before($size, 100%);
            background-color: $primary;
            border-radius: 60px 0 0 60px;
          }
        }

        &:nth-child(4) {
          width: 31.61vw;

          &:before {
            position: absolute;
            top: -1.58vw;
            right: -1.58vw;
            @include before(5vw, 5vw);
            background-color: transparent;
            border-radius: 50px;
            box-shadow: 2vw -2vw 0 $primary;
          }
        }
      }

      &:nth-child(3) .section__list-item {
        &:nth-child(1) {
          width: 23vw;

          &:after {
            position: absolute;
            top: calc(100% + 1.58vw);
            right: 0;
            @include before(28vw, 100%);
            background-color: $primary;
            border-radius: 0 60px 0 0;
          }
        }

        &:nth-child(2) {
          position: relative;
          right: 0;
          width: 23vw;
          background-color: transparent;
          z-index: 1000;

          .section__list-item__title {
            @extend .title;
            position: absolute;
            width: 33vw;
            margin-top: 2vw;
            margin-left: -10.5vw;
          }

          .section__list-item__text {
            position: absolute;
            width: 28vw;
            margin-left: -10.5vw;
            margin-top: 14vw;
          }
        }

        &:nth-child(3) {
          position: absolute;
          bottom: 0;
          right: 0;
          width: calc(100% - 23.15vw - 1.58vw - 1.58vw - 31.61vw);

          &:after {
            position: absolute;
            top: calc(-100% - 1.58vw);
            right: 0;
            @include before(100%, 100%);
            border-radius: 0 0 60px 60px;
            background-color: $primary;
          }

          &:before {
            position: absolute;
            top: calc(-100% - 1.58vw * 2);
            right: 0;
            @include before(20vw, 100%);
            @include svgBg($click);
            z-index: 100;
          }
        }
      }
    }
  }
}

.mobile .section {
  margin-top: 6vw;
  margin-bottom: 2vw;

  .title {
    margin-bottom: 5.12vw;
  }

  &__list {
    @extend .flex-col;
    gap: 6.15vw;

    &-item {
      padding: 4.1vw;
      border-radius: 20px;

      &__title {
        @include font-16($weight: 600, $height: 120%);
        margin-bottom: 2.05vw;
      }

      &__text {
        @include font-16();
      }
    }

    &-col {
      @extend .flex-col;
      gap: 6.15vw;
      position: relative;

      &:nth-child(1) .section__list-item:not(:nth-child(1)):not(:nth-child(3)):not(:last-child),
      &:nth-child(2) .section__list-item:not(:nth-child(3)),
      &:nth-child(3) {
        display: none;
      }
    }

    &-row {
      @extend .flex-row;
      justify-content: space-between;
      position: relative;

      .section__list-item:first-child {
        width: 54.1vw;
        z-index: 100;
      }

      .section__list-item:last-child {
        width: 32.3vw;
        height: 80vw;
        background-color: $primary;

        .section__list-item__title {
          @extend .col-container;
          gap: 4.3vw;
          width: min-content;
          margin: 1.02vw auto;
          @include font-20($height: 120%);

          &:after {
            @include before(25.6vw, 25.6vw);
            @include svgBg($click);
          }
        }

        .section__list-item__text {
          position: absolute;
          bottom: 0;
          right: 0;
          width: calc(100dvw - 4.1vw * 4);
          padding: 5vw 4.1vw 4.1vw;
          @include font-14();
          background-color: $primary;
          border-radius: 24px;
        }
      }
    }
  }
}

@media (min-width: 800px) and (max-width: 1400px) {
  .section__list-col:nth-child(2) .section__list-item:nth-child(2):before {
    top: calc(100% - 5vw + 2.2vw);
  }
}
</style>
