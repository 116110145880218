<template>
  <div class="contacts-footer">
    <div class="contacts-footer__left">
      <p class="contacts-footer__left-link" @click="push('/contacts')">{{ $t('contacts.footer_link_1') }}</p>
      <p class="contacts-footer__left-link" @click="push('/privacy')">{{ $t('contacts.footer_link_2') }}</p>
      <p class="contacts-footer__left-link" @click="push('/conditions')">{{ $t('contacts.footer_link_3') }}</p>
      <div class="contacts-footer__left-icons">
        <div class="contacts-footer__left-icons__item instagram" @click="goToInstagram"></div>
        <div class="contacts-footer__left-icons__item facebook" @click="goToFacebook"></div>
      </div>
    </div>

    <div class="contacts-footer__right" :key="key">
      <p class="contacts-footer__right-title">{{ $t('home.form_hint') }}</p>
      <form-input v-model="name" :label="$t('home.form_name')" />
      <form-input v-model="phone" :label="$t('home.form_phone')" />
      <base-button :text="$t('buttons.send')" @click="onSendClick" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import BaseButton from "@/components/Button/BaseButton.vue";
import { useRouter } from 'vue-router';
import FormInput from "@/components/Form/FormInput.vue";
import { useRender } from "@/composable/useRender";

const { push } = useRouter();
const { renderNotification } = useRender();

const url = process.env.NODE_ENV === 'development' ? `http://localhost:3000` : `https://demochat.vipmanage.org/api`;
const fullUrl = `${ url }/request/presentation`;

const name = ref('');
const phone = ref('');
const key = ref(0);

const goToFacebook = () => window.open('https://www.facebook.com/share/etC75r4qk2ENR3oL/?mibextid=LQQJ4d', '_blank');

const goToInstagram = () => window.open('https://www.instagram.com/smart.hotel.service?igsh=bWlyM3VqbGp0MW5v&utm_source=qr', '_blank');

const onSendClick = async () => {
  try {
    await fetch(fullUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({ userName: name.value, phone: phone.value })
    });
    renderNotification('successful_data_send');
    name.value = '';
    phone.value = '';
    key.value++;
  } catch (e) {
    console.log(e);
    renderNotification('error_data_send');
  }
};
</script>

<style scoped lang="scss">
.contacts-footer {
  background-color: $secondary;

  &__left {
    @extend .flex-col;

    &-link {
      @extend .pointer;
    }

    &-icons {
      @extend .flex-row, .pointer;

      &__item {
        &.instagram {
          @include svgBg($instagram);
        }

        &.facebook {
          @include svgBg($facebook);
        }
      }
    }
  }

  &__right {
    @extend .flex-col;

    :deep(.field__input) {
      background-color: white;
    }
  }
}

.desktop .contacts-footer {
  @extend .row-container;
  justify-content: space-between;
  gap: 5.95vw;
  padding: 3.3vw;
  border-radius: 80px;

  &__left, &__right {
    flex: 1;
  }

  &__right {
    position: relative;
    gap: 2.38vw;

    &:before {
      position: absolute;
      left: -3vw;
      @include before(0, 100%);
      border-right: 2px solid $grey;
    }

    .field:not(:first-child) {
      margin-top: -0.5vw;
    }
  }

  &__left {
    justify-content: center;
    gap: 4vw;

    &-link {
      @include font-24();
    }

    &-icons {
      gap: 1.05vw;

      &__item {
        @include square(5.3vw);
      }
    }
  }
}

.mobile .contacts-footer {
  @extend .flex-col;
  flex-direction: column-reverse;
  padding: 4.1vw;
  border-radius: 40px;

  &__right {
    gap: 6.15vw;
    margin-bottom: 12.05vw;

    &-title {
      margin-bottom: -2.05vw;
      @include font-16();
    }
  }

  &__left {
    gap: 6.9vw;
    align-items: center;

    &-link {
      @include font-18();
    }

    &-icons {
      gap: 4.1vw;

      &__item {
        @include square(20.5vw);
      }
    }
  }
}
</style>
