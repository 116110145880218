<template>
  <div class="panel">
    <panel-header />
    <link-button class="top right" :text="$t('buttons.free_month')" @click="redirectToTg" />
    <link-button class="bottom left" :text="$t('buttons.coworking_details')" @click="redirectToTg" />
    <div class="panel__content">
      <div class="panel__left">
        <p class="title">{{ $t('hotel.main_title') }}</p>
        <p class="panel__text">{{ $t('hotel.main_text') }}</p>
      </div>
      <img src="@/assets/images/hotel-1.png" alt="img" class="panel__right" />
    </div>
  </div>
</template>

<script setup lang="ts">
import LinkButton from "@/components/Button/LinkButton.vue";
import PanelHeader from "@/components/Panel/PanelHeader.vue";
import { useButton } from "@/composable/useButton";

const { redirectToTg } = useButton();
</script>

<style scoped lang="scss">
@import "src/assets/styles/components/main";

.panel {
  &__content {
    position: relative;
    width: calc(100% + 1.58vw);
  }

  &__right {
    position: absolute;
  }
}

.desktop .panel {
  margin-bottom: 13.9vw;

  &__content {
    height: 100%;
    overflow: hidden;
  }

  &__left {
    margin: calc(min(7.2vw, 10vh)) 0 calc(min(14vw, 22vh)) 2.64vw;
  }

  .title {
    width: 44vw;
    margin-bottom: 1.58vw;
  }

  &__text {
    width: 42.06vw;
    @include font-24();
  }

  &__right {
    top: 0;
    left: 41vw;
    width: 58.46vw;
    height: 98%;
    object-fit: contain;
  }
}

.mobile .panel {
  margin-bottom: 17.7vw;

  &__left {
    margin-top: 18vw;
  }

  .title {
    margin-bottom: 87vw;
  }

  &__text {
    margin-bottom: 18vw;
    @include font-18();
  }

  &__right {
    position: absolute;
    top: 18vw;
    left: -9vw;
    width: 114vw;
  }
}
</style>
