import { useRouter } from "vue-router";
import { useRender } from "@/composable/useRender";

export const useButton = () => {
  const { push } = useRouter();
  const { renderNotification } = useRender();

  const redirectToTg = () => window.open('https://t.me/umtrnBot', '_blank');

  const goToHotelPage = () => push('/hotel');

  const copyText = (text: string) => navigator.clipboard.writeText(text).then(() => setTimeout(() => renderNotification('copied'), 10));

  const redirectToEmail = () => window.open('mailto:sales@smarthotelservice.com', '_blank');

  return { redirectToTg, goToHotelPage, copyText, redirectToEmail };
};
