<template>
  <div class="scroll-button" @click="onClick"></div>
</template>

<script setup lang="ts">
const onClick = () => document.getElementById('container')?.scrollTo({ top: 0, behavior: 'smooth' });
</script>

<style scoped lang="scss">
.scroll-button {
  @extend .circle, .pointer;
  position: fixed;
  bottom: 4vw;
  right: 4vw;
  animation: appear 0.2s 1 both;
  background-color: #F4F4F4B2 !important;
  backdrop-filter: blur(2px);
  transform: rotate(0.5turn);
  z-index: 2000;
}

.desktop .scroll-button {
  @include icon($arrow, 5.3vw, 2.31vw);
}

.mobile .scroll-button {
  @include icon($arrow, 14.1vw, 6.15vw);
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
