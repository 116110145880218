<template>
  <div class="section">
    <p class="title">{{ $t('hotel.features_title') }}</p>
    <p class="section__text">{{ $t('hotel.features_text') }}</p>
    <div class="section__list">
      <div v-for="n in 10" :key="n" class="section__list-item">
        <img class="section__list-item__left" :src="require(`@/assets/images/hotel-features-${n}.png`)" alt="" />
        <div class="section__list-item__right">
          <p class="section__list-item__right-title">{{ $t(`hotel.features_item_${ n }_title`) }}</p>
          <p class="section__list-item__right-text">{{ $t(`hotel.features_item_${ n }_text`) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.section {
  &__list {
    @extend .flex-col;
  }
}

.desktop .section {
  margin-bottom: 9vw;

  .title {
    margin-bottom: 1.05vw;
  }

  &__text {
    margin-bottom: 3.3vw;
    @include font-20();
  }

  &__list {
    gap: 4.63vw;

    &-item {
      @extend .row-container;

      &__right {
        gap: 1.05vw;
        border-top: 4px solid $primary;

        &-title {
          @include font(2.5vw, 120%, $text, 600);
        }

        &-text {
          width: 42.32vw;
          @include font-24();
        }
      }

      &:nth-child(2n - 1) {
        gap: 10.4vw;

        .section__list-item__left {
          width: 50.5vw;
          margin-left: -12.25vw;
        }

        .section__list-item__right {
          padding: 2.25vw 1.5vw 2vw 0;
          border-right: 4px solid $primary;
          border-radius: 0 80px 0 0;
        }
      }

      &:nth-child(2n) {
        flex-direction: row-reverse;
        gap: 3vw;

        .section__list-item__left {
          width: 68.5vw;
          margin-right: -24vw;
        }

        .section__list-item__right {
          padding: 2.25vw 0 2vw 2.25vw;
          border-left: 4px solid $primary;
          border-radius: 80px 0 0 0;
        }
      }

      &:nth-child(5) {
        margin-top: -1vw;
      }

      &:nth-child(6) {
        margin-top: -1.5vw;
      }

      &:nth-child(8) {
        margin-top: -2.5vw;

        .section__list-item__left {
          margin-right: -35.75vw;
        }
      }

      &:nth-child(9) {
        margin-top: -3vw;
      }

      &:nth-child(10) {
        margin-top: -4vw;
      }
    }
  }
}

.mobile .section {
  margin-bottom: 20vw;

  .title {
    margin-bottom: 4.1vw;
  }

  &__text {
    margin-bottom: 9.74vw;
    @include font-16();
  }

  &__list {
    gap: 6.15vw;

    &-item {
      @extend .flex-col;
      flex-direction: column-reverse;
      padding: 4.1vw;
      border-top: 4px solid $primary;

      &__right {
        width: 87.7vw;

        &-title {
          margin-bottom: 2.05vw;
          @include font-22($weight: 600, $height: 120%);
        }

        &-text {
          margin-bottom: 4.1vw;
          @include font-18();
        }
      }

      &__left {
        width: 105vw;
      }

      &:nth-child(2n - 1) {
        margin-left: -4.1vw;
        border-right: 4px solid $primary;
        border-top-right-radius: 60px;

        .section__list-item__left {
          margin-left: -22vw;
        }
      }

      &:nth-child(2n) {
        margin-right: -4.1vw;
        border-left: 4px solid $primary;
        border-top-left-radius: 60px;

        .section__list-item__left {
          margin-left: 2vw;
        }
      }
    }
  }
}
</style>
