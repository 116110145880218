import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from "@/views/HomeView.vue";
import ClientView from "@/views/ClientView.vue";
import HotelView from "@/views/HotelView.vue";
import ContactsView from "@/views/ContactsView.vue";
import ConditionView from "@/views/ConditionView.vue";
import PrivacyView from "@/views/PrivacyView.vue";

const routes: Array<RouteRecordRaw> = [{
  path: '',
  redirect: '/about'
}, {
  path: '/about',
  name: 'about',
  component: HomeView,
}, {
  path: '/client',
  name: 'client',
  component: ClientView,
}, {
  path: '/hotel',
  name: 'hotel',
  component: HotelView,
}, {
  path: '/contacts',
  name: 'contacts',
  component: ContactsView,
}, {
  path: '/conditions',
  name: 'conditions',
  component: ConditionView,
}, {
  path: '/privacy',
  name: 'privacy',
  component: PrivacyView,
}];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  if (from.query?.lang && to.query?.lang !== from.query?.lang) {
    return { ...to, query: { ...to.query, lang: from.query.lang } };
  }
});

export default router
