export const ua = {
  improve_your_business: 'ПОКРАЩУЙ СВІЙ БІЗНЕС РАЗОМ З НАМИ',
  free_presentation: 'БЕЗКОШТОВНА LIVE ПРЕЗЕНТАЦІЯ',
  copied: 'Скопійовано',
  successful_data_send: 'Ваш електронний лист успішно надіслано',
  error_data_send: "Не вдається надіслати вашу електронну пошту",

  tabs: {
    about: 'Про нас',
    client: 'Для клієнта',
    hotel: 'Для готелю',
    contacts: 'Контакти',
  },

  buttons: {
    free_month: 'Безкоштовний місяць',
    our_services: 'Наші послуги',
    send: 'Відправити',
    coworking_details: 'Деталі співробітництва',
    order_presentation: 'Замовити презентацію',
  },

  home: {
    title_1: 'Smart',
    title_2: 'Hotel',
    title_3: 'Service',
    subtitle: 'Інноваційний додаток створений для забезпечення гостям надзвичайного комфорту та зручності під час перебування водночас з оптимізацією роботи персонала та підвищенням прибутковості готелю.',
    about_title: 'ПРО НАС',
    about_text: 'Представляємо Smart Hotel Service, прогресивний застосунок, створений, щоб спростити та покращити готельний досвід для гостей, а також збільшити прибуток готелю.<br/>Завдяки Smart Hotel Service гості можуть запросити різні готельні послуги, а ви зможете легко перевести свій бізнес на новий рівень.<br/>Запровадження нашої системи дозволяє вашому готелю не лише підвищити рівень обслуговування, а й стати лідером у сфері гостинності, забезпечуючи винятковий досвід для кожного гостя.',
    form_title: 'ІНДИВІДУАЛЬНА ПРЕЗЕНТАЦІЯ В ОНЛАЙН РЕЖИМІ САМЕ ДЛЯ ТЕБЕ',
    form_hint: 'Заповни та відправ свої дані і ми передзвонимо та домовимось про БЕЗКОШТОВНУ презентацію!',
    form_name: 'Ім\'я та Прізвище',
    form_phone: 'Номер Телефону',
    benefits_title: 'ВСЕ В ОДНІЙ СИСТЕМІ',
    benefits_subtitle: 'У сучасному світі гостинність є одним із ключових факторів, що визначають успіх готелю. Для забезпечення високого рівня сервісу та задоволеності клієнтів ми пропонуємо інноваційну систему управління, яка покращує всі аспекти роботи готелю.',
    exp_title: 'ПЕРСОНАЛІЗОВАНИЙ ДОСВІД ГОСТЕЙ',
    exp_text: 'Наша система надає гостям індивідуальний додаток, який значно полегшує взаємодію з готелем. Завдяки додатку гості можуть:',
    exp_item_1: 'Замовляти їжу прямо до номера, обираючи з різноманітного меню ресторану.',
    exp_item_2: 'Залишати запити на прибирання, вибираючи зручний час для обслуговування.',
    exp_item_3: 'Замовляти таксі та інші транспортні послуги безпосередньо через додаток.',
    exp_item_4: 'Спілкуватися з ресепшеном для швидкого вирішення будь-яких питань або запитів.',
    exp_item_5: 'Резервувати спа-процедури та інші додаткові послуги готелю.',
    exp_button: 'Усі переваги для гостей',
    tool_title: 'ЕФЕКТИВНИЙ ІНСТРУМЕНТ ДЛЯ ПЕРСОНАЛУ',
    tool_text: 'Кожен працівник готелю має доступ до персоналізованого робочого кабінету, який оптимізує виконання їхніх завдань:',
    tool_item_1: 'Отримання запитів від гостей у реальному часі з можливістю негайної відповіді.',
    tool_item_2: 'Моніторинг виконання запитів з відстеженням часу та якості надання послуг.',
    tool_item_3: 'Графіки резервацій додаткових послуг, що забезпечує безперебійний та злагоджений процес обслуговування.',
    tool_item_4: 'Аналіз ефективності роботи персоналу на основі відгуків гостей та часу виконання запитів.',
    tool_item_5: 'Надання зворотного зв\'язку гостям на основі їхніх відгуків, що допомагає формувати довірливі відносини та підвищує рівень задоволеності клієнтів.',
    tool_button: 'Усі переваги для готелю',
    offer_title: 'НОВИЙ РІВЕНЬ КОМФОРТУ ГОСТЕЙ',
    offer_text: 'Ми з радістю відповімо на всі Твої додаткові запитання та підтримаємо Тебе на кожному етапі впровадження системи, допомагаючи Твоєму бізнесу вийти на новий рівень успіху.',
    offer_button: 'Хочу покращити бізнес!',
    reviews_title: 'РЕАЛЬНІ ВІДГУКИ РЕАЛЬНИХ ЛЮДЕЙ',
    review_1_name: 'Анна, 48 років',
    review_1_type: 'керуюча готелем',
    review_1_text: 'Спочатку я сумнівалась у доцільності впровадження цієї системи, але результати перевершили всі очікування. Програма значно спростила наші процеси і підвищила ефективність роботи.  Це справді вдала інвестиція для нашого готелю!',
    review_2_name: 'Дмитро, 44 роки',
    review_2_type: 'власник готелю',
    review_2_text: 'З впровадженням цієї системи кожен гість залишається задоволеним нашим сервісом. Ми отримуємо багато похвал за те, що йдемо в ногу з часом, і помітили значний ріст прибутку. Ця система справді покращила наші процеси і підвищила загальний рівень обслуговування.',
    review_3_name: 'Марія, 32 роки',
    review_3_type: 'гостя готелю',
    review_3_text: 'Я була приємно здивована, коли, зісканувавши код, отримала доступ до додатку готелю. Все було надзвичайно зручно: я змогла замовити їжу, організувати прибирання та скористатися іншими послугами, не виходячи з номера. Це справжній класний сервіс!',
    review_4_name: 'Настя, 29 років',
    review_4_type: 'адміністратор готелю',
    review_4_text: 'Спочатку я була скептично налаштована, але вона виявилася надзвичайно корисною. Система значно полегшила нашу роботу, автоматизувавши багато рутинних завдань і надавши зручний доступ до інформації.  Це справжній прорив для нашого готелю!',
    review_5_name: 'Ігор, 52 роки',
    review_5_type: 'гість готелю',
    review_5_text: 'Я зміг подовжити своє перебування, не виходячи з номера, і був приємно здивований, як просто і зручно це зробити через додаток. Хоч я не дуже з новими технологіями, ця система виявилася дуже інтуїтивною і легкою у використанні. ',
    points_title: 'ЩО ВИ ОТРИМАЄТЕ, ОНОВИВШИ СВІЙ БІЗНЕС?',
    points_text: 'Впровадження нашого додатку приносить численні переваги, що сприяють покращенню якості обслуговування та ефективності вашого бізнесу. Ось основні переваги нашої системи:',
    points_item_1_title: 'Сучасні технології: ',
    points_item_1_text: 'Впровадження сучасних технологій оптимізує операції та підвищує загальну ефективність готелю.',
    points_item_2_title: 'Комфорт гостей:',
    points_item_2_text: 'Завдяки зручному додатку гості замовляють та отримують послуги не виходячи з номеру. Більше не треба дзвонити або йти на ресепшн.',
    points_item_3_title: 'Персоналізоване обслуговування:',
    points_item_3_text: 'Завдяки індивідуальному додатку гості отримують зручний доступ до всіх послуг готелю.',
    points_item_4_title: 'Позитивні відгуки:',
    points_item_4_text: 'Високий рівень обслуговування призводить до збільшення кількості позитивних відгуків та підвищення лояльності гостей.',
    points_item_5_title: 'Правильний фокус:',
    points_item_5_text: 'Персонал може зосередитися на виконанні важливіших завдань, оскільки повторювані адміністративні обов\'язки автоматизовані.',
    points_item_6_title: 'Контроль якості роботи готелю:',
    points_item_6_text: 'Адміністратори можуть відстежувати виконання запитів та контролювати якість обслуговування.',
    points_item_7_title: 'Реклама та продаж:',
    points_item_7_text: 'Можливість реклами та продажу власних послуг і послуг партнерів через додаток дозволяє готелю привертати більше клієнтів та збільшувати обсяги продажів.',
    points_item_8_title: 'Ріст прибутковості:',
    points_item_8_text: 'Наша система підвищує доходи готелю, залучаючи більше клієнтів і збільшуючи обсяги продажів. Це безпосередньо сприяє зростанню загальної прибутковості готелю.',
    points_item_9_title: 'Підтримка 24/7:',
    points_item_9_text: 'Додаток забезпечує цілодобову онлайн-підтримку як для гостей, так і для персоналу готелю, що дозволяє швидко вирішувати будь-які операційні або технічні питання.',
    points_item_10_title: 'Навчання персоналу:',
    points_item_10_text: 'Пропонуємо можливість навчання вашого персоналу, щоб вони могли ефективно використовувати всі функції системи.',
    points_item_11_title: 'Задоволені клієнти:',
    points_item_11_text: 'Покращення рівня задоволеності клієнтів завдяки зручному доступу до послуг та швидкому реагуванню на їхні запити.',
    points_item_12_title: 'Гнучкість та різноманіття функцій:',
    points_item_12_text: 'Система "конструктор" - ви обираєте саме той набір функцій, який найкраще відповідає вашим потребам.',
  },

  client: {
    main_title: 'НОВИЙ РІВЕНЬ КОМФОРТУ ГОСТЕЙ',
    main_text: 'Наш додаток робить перебування гостей у готелі максимально комфортним, дозволяючи замовляти будь-які послуги безпосередньо з номера. Усі питання вирішуються швидко завдяки цілодобовій онлайн-підтримці, а доступ до всієї необхідної інформації про готель завжди під рукою. Це забезпечує безтурботний відпочинок і високий рівень сервісу.',
    steps_title: 'ТРИ ПРОСТІ КРОКИ',
    steps_item_1: 'Створіть персоналізоване посилання на додаток вашого готелю заповнивши просту форму в кабінеті для персоналу',
    steps_item_2: 'Дозвольте гостю відсканувати QR код який з\'явився на екрані, або вишліть посилання на електронну пошту',
    steps_item_3: 'Виконуйте запити гостей, збирайте позитивні відгуки та насолоджуйтесь роботою.',
    features_title: 'НОВІ МОЖЛИВОСТІ ДЛЯ ГОСТЕЙ',
    features_item_1_title: 'Замовлення послуг: ',
    features_item_1_text: 'Гості можуть легко замовити прибирання, додаткові подушки, таксі, їжу та напої, а також забронювати спа-процедури, сауну або масаж безпосередньо з номера.',
    features_item_2_title: 'Запити на ресепшн:',
    features_item_2_text: 'Будь-які запити або питання можна відправити прямо через додаток, що спрощує комунікацію з готелем.',
    features_item_3_title: 'Відгуки:',
    features_item_3_text: 'Гості можуть залишити відгуки на будь-який запит, що допомагає готелю покращувати якість обслуговування.',
    features_item_4_title: 'Сповіщення:',
    features_item_4_text: 'Гості отримують сповіщення про статус своїх запитів, включаючи початок, завершення або скасування запиту.',
    features_item_5_title: 'Автоматичне блокування:',
    features_item_5_text: 'Після виїзду доступ до послуг готелю, таких як замовлення їжі або бронювання, автоматично блокується, але інформація та можливість бронювання номерів залишаються доступними.',
    features_item_6_title: 'Інформація про готель:',
    features_item_6_text: 'Додаток надає доступ до інформації про готель, ресторан та дозвілля, що дозволяє гостям зручно ознайомлюватися з усіма пропозиціями.',
    features_item_7_title: 'Додаткові функції:',
    features_item_7_text: 'Для зручності гостей та готелю є онлайн-оплата, смс-розсилка новин і знижок. Якщо потрібна спеціальна функція, ми можемо реалізувати її після обговорення деталей.',
    features_item_8_title: 'Функція прямого зв\'язку:',
    features_item_8_text: 'Додаток надає можливість прямого зв\'язку з адміністратором або співробітником ресепшена. Гості можуть в будь-який час надсилати повідомлення безпосередньо співробітнику через додаток, що забезпечує швидке вирішення питань і підвищує рівень обслуговування.',
    features_item_9_title: 'Історія запитів:',
    features_item_9_text: 'У додатку зберігається історія всіх запитів, резервацій та бронювань, що дозволяє легко переглядати попередні дії.',
    features_item_10_title: 'ПОЧНИ ВЖЕ ЗАРАЗ',
    features_item_10_text: 'Клацай тут, і ми проведемо тобі індивідуальну презентацію додатку',
    features_item_11_title: 'Замовлення додаткових послуг:',
    features_item_11_text: 'СПА чи катання на лижах - гості можуть дізнатись інформацію та замовити будь яку послугу, яку готель пропонує з телефону.',
  },

  hotel: {
    main_title: 'НОВИЙ РІВЕНЬ БІЗНЕСУ',
    main_text: 'Впровадження нашого додатку суттєво підвищить ефективність вашого готелю, автоматизуючи рутинні процеси і полегшуючи управління. Зручність для гостей, швидкість обробки запитів та інтеграція сучасних технологій піднімуть рівень обслуговування, що позитивно вплине на репутацію і прибутковість готелю.',
    features_title: 'ЕФЕКТИВНИЙ ІНСТРУМЕНТ ДЛЯ ПЕРСОНАЛУ ТА ВЛАСНИКІВ ГОТЕЛІВ',
    features_text: 'Кожна з цих функцій розроблена для підвищення ефективності управління готелем, покращення якості обслуговування гостей та забезпечення гнучкості в налаштуванні системи відповідно до потреб вашого бізнесу.',
    features_item_1_title: 'Інформація про Готель та Послуги',
    features_item_1_text: 'Додаток містить усю необхідну інформацію про готель, включаючи фотографії, графіки роботи, меню ресторану, прайс-листи та описи додаткових послуг. Гості матимуть доступ до цієї інформації навіть після виїзду з готелю.',
    features_item_2_title: 'Управління запитами',
    features_item_2_text: 'Персонал отримує запити через додаток і має можливість бачити, скільки часу запит перебуває в системі, а також хто його створив. Працівники можуть приймати, скасовувати або завершувати запити. Після виконання запит автоматично зберігається в історії, де адміністратор завжди бачить, хто виконував запит, і яка була тривалість його виконання. Це забезпечує прозорість і контроль за якістю обслуговування.',
    features_item_3_title: 'Графік Резервацій Додаткових Послуг',
    features_item_3_text: 'Гості можуть бронювати додаткові послуги, такі як масаж або сауна, через додаток, а персонал отримує ці запити для обробки. Після підтвердження, запит з\'являється в графіку конкретної послуги. Додатково є можливість налаштувати бронювання послуг через прямий зв\'язок (чат) зі співробітником. Також ви можете блокувати всі вільні слоти для прибирання чи ремонту.',
    features_item_4_title: 'Графік бронювань',
    features_item_4_text: 'Додаток для персоналу надає зручний графік бронювань, у якому працівники можуть підтверджувати або скасовувати бронювання, а також зв\'язуватися з клієнтами. У графіку можна вручну додати бронювання, якщо гості телефонують напряму, що дозволяє зберігати всю інформацію в одному місці.',
    features_item_5_title: 'Управління Відгуками та Інформацією про Гостей',
    features_item_5_text: 'Усі відгуки, залишені гостями, зберігаються в додатку разом з контактною інформацією гостей. Це дозволяє працівникам надавати зворотний зв\'язок клієнтам або швидко вирішувати будь-які проблеми, підвищуючи якість обслуговування.',
    features_item_6_title: 'База Співробітників',
    features_item_6_text: 'Система дозволяє створювати акаунти для необмеженої кількості працівників, кожному з яких призначається конкретний відділ. Це дозволяє працівникам отримувати лише ті запити, що стосуються їхнього відділу, підвищуючи ефективність роботи.',
    features_item_7_title: 'Реклама Послуг Готелю та Партнерів',
    features_item_7_text: 'Ви можете додавати необмежену кількість рекламних банерів у додаток, які будуть вести на сторінку з інформацією про рекламовану послугу вашого готелю або ваших партнерів. Це буде впливати на зростання продажів і прибутку. Реклама партнерів оплачується окремо за лояльною ціною.',
    features_item_8_title: 'Мобільна та Комп\'ютерна Версія',
    features_item_8_text: 'Додаток для персоналу створений у двох варіантах — мобільному та десктопному, що забезпечує зручність роботи в будь-якому місці та на будь-якому пристрої.',
    features_item_9_title: 'Цілодобова Підтримка',
    features_item_9_text: 'Наша служба підтримки працює цілодобово, щоб допомогти вирішити будь-які операційні або технічні питання. Ми завжди на зв\'язку, щоб забезпечити безперебійну роботу вашого готелю.',
    features_item_10_title: 'Додаткові Функції',
    features_item_10_text: 'Програма включає такі додаткові функції, як програмне забезпечення для друку чеків-замовлень на кухні, розсилка SMS-повідомлень з акціями і пропозиціями, а також друк QR-кодів з меню або будь-якою іншою інформацією.',
    qr_title: 'QR КОДИ ДЛЯ КОЖНОГО ПРИМІЩЕННЯ',
    qr_text: 'Наша система дозволяє створювати та друкувати спеціальні QR-коди для меню ресторану, які розміщуються на столиках. Це забезпечує гостям зручний спосіб замовлення їжі та напоїв безпосередньо зі своїх смартфонів. Ця функція значно покращує досвід перебування в ресторані, підвищуючи рівень зручності та ефективності як для гостей, так і для персоналу. Впровадження QR-кодів спрощує процес обслуговування і знижує навантаження на персонал, дозволяючи зосередитися на якості обслуговування та задоволенні потреб клієнтів.',
    qr_item_1_title: 'Розміщення QR-кодів:',
    qr_item_1_text: 'Кожен столик у ресторані оснащується унікальним QR-кодом, який веде до цифрового меню.',
    qr_item_2_title: 'Сканування та замовлення:',
    qr_item_2_text: 'Гість готелю сканує QR-код за допомогою свого смартфона та отримує доступ до повного меню ресторану. Після вибору страв і напоїв, гість може здійснити замовлення через додаток.',
    qr_item_3_title: 'Обробка замовлення:',
    qr_item_3_text: 'Замовлення автоматично надходить до робочого кабінету співробітника, де він бачить:<br/><br/>- Номер столика, з якого було зроблено замовлення.<br/> - Деталі замовлених страв і напоїв.<br/> - Обраний гостем спосіб оплати (готівкою, карткою або через додаток).',
    qr_item_4_title: 'Зручність для гостей:',
    qr_item_4_text: 'Гості можуть легко переглядати меню, вибирати страви, та вказувати свої побажання (наприклад, алергії або інші дієтичні потреби) без необхідності чекати офіціанта. Це підвищує швидкість обслуговування та забезпечує бездоганний сервіс.',
    qr_item_5_title: 'Переваги для персоналу:',
    qr_item_5_text: 'Співробітники ресторану отримують замовлення в електронному вигляді, що мінімізує ризик помилок при передачі інформації. Вони можуть швидко підготувати замовлення та передати його кухні для виконання.',
    qr_item_6_title: 'Оплата:',
    qr_item_6_text: 'Гості можуть обрати зручний для них спосіб оплати, що робить процес завершення замовлення простим і зрозумілим.',
    qr_hint: '*Ця функція надається додатково та сплачується окремо',
  },

  contacts: {
    footer_link_1: 'КОНТАКТИ',
    footer_link_2: 'ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ',
    footer_link_3: 'ПРАВИЛА ТА УМОВИ',
    main_title_header: 'КОНТАКТИ',
    main_title_content: 'Зв\'яжися з нами зручним тобі шляхом, та ми з радістю відповімо на усі додаткові питання, проведемо презентацію онлайн та надамо усі деталі співробітницва.',
    contact_icon_telegram: 'TELEGRAM',
    contact_icon_email: 'E-MAIL',
    contact_icon_phone: 'ТЕЛЕФОН',
  },

  privacy: {
    header: 'ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ',
    text_0: 'Smart Hotel Service (далі "Компанія", "ми", "нас") поважає вашу приватність і зобов\'язується захищати ваші особисті дані. Ця Політика конфіденційності пояснює, як ми збираємо, використовуємо, розголошуємо та захищаємо вашу інформацію при використанні нашого мобільного додатку для готелів (далі "Додаток").',
    title_1: '1. Інформація, яку ми збираємо',
    text_1: 'Ми можемо збирати такі типи інформації:<br/><br/>- **Особисті дані:** Під час реєстрації або використання Додатку ми можемо збирати ваше ім’я, електронну адресу, номер телефону та інші контактні дані.<br/>- **Дані про використання:** Ми автоматично збираємо інформацію про те, як ви взаємодієте з нашим Додатком, включаючи IP-адресу, тип пристрою, операційну систему, тип браузера та інші дані про використання.<br/>- Фінансові дані: Якщо ви здійснюєте платіж через наш Додаток, ми можемо збирати платіжну інформацію, таку як номер кредитної картки та платіжний адрес.',
    title_2: '2. Як ми використовуємо вашу інформацію',
    text_2: 'Ми використовуємо вашу інформацію для:<br/><br/>- Надання та покращення нашого Додатку та послуг.<br/>- Обробки транзакцій та управління вашими замовленнями.<br/>- Надання підтримки клієнтів та відповідей на ваші запити.<br/>- Надання вам оновлень, новин та іншої інформації про наші послуги, якщо ви погодились отримувати такі комунікації.<br/>- Аналізу використання Додатку для покращення наших продуктів і послуг.',
    title_3: '3. Як ми розголошуємо вашу інформацію',
    text_3: 'Ми не розголошуємо вашу особисту інформацію третім особам, за винятком випадків:<br/><br/>- Постачальники послуг: Ми можемо передавати вашу інформацію нашим довіреним постачальникам послуг, які допомагають нам у наданні та підтримці Додатку.<br/>- Правові вимоги: Ми можемо розголошувати вашу інформацію у разі вимоги законодавства або в рамках правових процедур.',
    title_4: '4. Безпека даних',
    text_4: 'Ми застосовуємо відповідні технічні та організаційні заходи для захисту вашої інформації від несанкціонованого доступу, втрати або розголошення. Тим не менше, жодна система безпеки не є абсолютно непроникною, і ми не можемо гарантувати абсолютну безпеку ваших даних.',
    title_5: '5. Ваші права',
    text_5: 'Ви маєте право:<br/><br/>- Запитувати доступ до ваших особистих даних.<br/>- Виправляти або оновлювати вашу інформацію.<br/>- Запитувати видалення ваших даних, якщо це дозволено законом.<br/>- Відмовитися від отримання маркетингових повідомлень.',
    title_6: '6. Зміни в політиці конфіденційності',
    text_6: 'Ми можемо час від часу оновлювати цю Політику конфіденційності. Останні зміни будуть опубліковані на цій сторінці. Рекомендуємо вам періодично перевіряти цю Політику для ознайомлення з будь-якими змінами.',
    title_7: '7. Контактна інформація',
    text_7: 'Якщо у вас є питання або зауваження щодо цієї Політики конфіденційності, будь ласка, зв\'яжіться з нами за адресою:<br/><br/>**Smart Hotel Service**<br/>[Ваша адреса]<br/>[Ваша адреса]<br/>[Ваша електронна пошта]',
  },

  conditions: {
    header: 'УМОВИ ВИКОРИСТАННЯ',
    title_1: '1. Загальні положення',
    text_1: '1.1 Вітаємо вас на сайті Smart Hotel Service ("Ми", "Наша компанія"). Ці Умови використання ("Умови") регулюють використання вами нашого мобільного застосунку для готелів ("Застосунок"). Користуючи наш Застосунок, ви погоджуєтеся дотримуватися цих Умов. Якщо ви не згодні з цими Умовами, будь ласка, не використовуйте наш Застосунок.<br/><br/>1.2 Ми залишаємо за собою право в будь-який час змінювати ці Умови. Всі зміни набирають чинності з моменту їх публікації в Застосунку. Ви зобов\'язані періодично перевіряти ці Умови на предмет змін.',
    title_2: '2. Використання Застосунку',
    text_2: '2.1 Наш Застосунок призначений для використання в якості інструменту для управління готелями та бронювання номерів.<br/><br/>2.2 Ви зобов\'язані використовувати Застосунок тільки в законних цілях і відповідно до всіх застосовних законів і нормативних актів.<br/><br/>2.3 Ви не маєте права використовувати наш Застосунок для будь-яких незаконних або несанкціонованих цілей, включаючи, але не обмежуючись, спробами зламати наш Застосунок або отримати несанкціонований доступ до нього.',
    title_3: '3. Реєстрація та акаунт',
    text_3: '3.1 Для використання деяких функцій нашого Застосунку вам може знадобитися створити акаунт. Ви погоджуєтеся надати точну і актуальну інформацію при реєстрації.<br/><br/>3.2 Ви несете відповідальність за підтримання конфіденційності ваших даних для входу в акаунт і за всі дії, що здійснюються з вашого акаунту.<br/><br/>3.3 Ви зобов\'язані негайно повідомити нас про будь-яке несанкціоноване використання вашого акаунту або будь-які інші порушення безпеки.',
    title_4: '4. Інтелектуальна власність',
    text_4: '4.1 Весь контент, доступний через наш Застосунок, включаючи текст, графіку, логотипи, іконки, зображення та програмне забезпечення, є власністю Smart Hotel Service або її ліцензіарів і охороняється законами про авторське право та іншими законами про інтелектуальну власність.<br/><br/>4.2 Ви не маєте права копіювати, змінювати, розповсюджувати, відтворювати або продавати будь-який контент без нашого попереднього письмового дозволу.',
    title_5: '5. Конфіденційність',
    text_5: '5.1 Ваше використання Застосунку підлягає нашій Політиці конфіденційності, яка визначає, як ми збираємо, використовуємо та захищаємо вашу особисту інформацію.<br/><br/>5.2 Використовуючи наш Застосунок, ви погоджуєтеся з умовами нашої Політики конфіденційності.',
    title_6: '6. Відповідальність',
    text_6: '6.1 Ми не несемо відповідальності за будь-які прямі, непрямі, випадкові, особливі чи наслідкові збитки, що виникають внаслідок використання або неможливості використання нашого Застосунку.<br/><br/>6.2 Ми не гарантуємо безперебійного або безпомилкового функціонування Застосунку і не несемо відповідальності за будь-які помилки або неточності.',
    title_7: '7. Зміни та припинення',
    text_7: '7.1 Ми залишаємо за собою право в будь-який час змінювати або припиняти функціонування Застосунку повідомивши про це заздалегідь.<br/><br/>7.2 Ви зобов\'язані припинити використання Застосунку, якщо ви не погоджуєтеся з будь-якими змінами в цих Умовах.\n',
    title_8: '8. Застосовне право',
    text_8: '8.1 Ці Умови підлягають законам України. Всі суперечки, що виникають у зв\'язку з цими Умовами, вирішуються в судах України.',
    title_9: '9. Контактна інформація',
    text_9: '9.1 Якщо у вас є питання або коментарі щодо цих Умов, будь ласка, зв\'яжіться з нами за адресою:<br/><br/>**Smart Hotel Service**<br/>[Ваша адреса]<br/>[Ваша адреса]<br/>[Ваша електронна пошта]',
  },

  drop_menu: {
    header: 'Розділи',
    about_us: 'Про нас',
    contact: 'Контакти',
    client: 'Для клієнта',
    hotel: 'Для готелю',
    privacy: 'ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ',
    condition: 'УМОВИ ВИКОРИСТАННЯ'
  }
};
