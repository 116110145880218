<template>
  <div class="section">
    <div class="section__left">
      <p class="title">{{ $t('client.steps_title') }}</p>
      <div class="section__left-list">
        <div v-for="n in 3" :key="n" class="section__left-list__item">
          <p class="section__left-list__item-number">{{ n }}</p>
          <p class="section__left-list__item-text">{{ $t(`client.steps_item_${ n }`) }}</p>
        </div>
      </div>
    </div>
    <img src="@/assets/images/client-2.png" alt="img" class="section__right" />
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
.section {
  position: relative;

  &__left {
    @extend .flex-col;

    &-list {
      @extend .flex-col;

      &__item {
        @extend .row-container;
      }
    }
  }
}

.desktop .section {
  margin-bottom: 16vw;

  .title {
    margin: 4.83vw 0 2.38vw;
  }

  &__right {
    position: absolute;
    top: 0;
    left: 46vw;
    width: 64.15vw;
  }

  &__left-list {
    gap: 2.4vw;
    margin-left: 4.63vw;

    &__item {
      gap: 2.64vw;

      &-number {
        width: 4vw;
        @include font(6.61vw, 120%, $text, 600);
      }

      &-text {
        width: 31.7vw;
        @include font-24();
      }
    }
  }
}

.mobile .section {
  margin-bottom: 4vw;

  .title {
    margin-bottom: 6.15vw;
  }

  &__right {
    width: 132vw;
    margin-left: -8vw;
  }

  &__left-list {
    gap: 6.15vw;
    margin-bottom: 6.2vw;

    &__item {
      gap: 4.1vw;

      &-number {
        min-width: 9.23vw;
        @include font(15.38vw, 120%, $text, 600);
      }

      &-text {
        @include font-18();
      }
    }
  }
}
</style>
