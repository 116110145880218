<template>
  <div class="panel__header">
    <project-logo />
    <div class="panel__header-tabs">
      <p v-for="tab in tabs" :key="tab" class="panel__header-tabs__item"
         :class="{'panel__header-tabs__item--selected': tab === selectedTab}" @click="push(tab)">
        {{ $t(`tabs.${ tab }`) }}
      </p>
    </div>
<!--    <language-selector />-->
    <div class="panel__header-menu" @click="toggleMenu"></div>
    <menu-popup v-if="isMenuVisible" :tabs="tabs" :selected-tab="selectedTab" @close="toggleMenu"/>
  </div>
</template>

<script setup lang="ts">
import ProjectLogo from "@/components/ProjectLogo.vue";
import { computed, ref } from "vue";
import LanguageSelector from "@/components/LanguageSelector.vue";
import { useRoute, useRouter } from "vue-router";
import MenuPopup from "@/components/Popup/MenuPopup.vue";

const { push } = useRouter();
const route = useRoute();

const tabs = ref(['about', 'client', 'hotel', 'contacts']);
const isMenuVisible = ref(false);

const toggleMenu = () => {
  isMenuVisible.value = !isMenuVisible.value;
};

const selectedTab = computed((): string => {
  const path = route.path.slice(1);
  if (path === 'privacy' || path === 'conditions') {
    return 'hotel';
  } else {
    return path;
  }
});
</script>

<style scoped lang="scss">
.panel__header {
  @extend .row-container;
  position: relative;

  &-tabs {
    @extend .row-container, .rounded;
    gap: 0.8vw;
    height: 5.03vw;
    margin-left: 1.72vw;
    padding: 0.66vw;
    background-color: white;
    box-sizing: border-box;

    &__item {
      @extend .row-container, .pointer, .rounded;
      height: calc(100% - 0.8vw * 2);
      padding: 0.8vw;
      @include font-20();

      &--selected {
        background-color: $text;
        color: white;
      }
    }
  }

  &-menu {
    @extend .pointer;
    @include icon($menu, 16.15vw);
  }
}

.desktop .panel__header-menu {
  display: none;
}

.mobile .panel__header {
  position: absolute;
  top: calc(-22.3vw + 4.1vw);
  left: 0;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.5rem;

  &-tabs {
    display: none;
  }
}
</style>
