import { createApp } from 'vue';
import App from './App.vue';
import i18n from './i18n';
import router from './router';
import { createGtm } from '@gtm-support/vue-gtm';
import VueGtag from 'vue-gtag';

const app = createApp(App)

app.use(VueGtag, {
    config: {
        id: 'G-VE1EGGXMMX'
    }
});
app.use(
    createGtm({
        // id: process.env.VUE_APP_GTM_ID,
        id: "GTM-KQF2RN2H",
        enabled: true,
        debug: true,
        loadScript: true,
    })
)

app.use(i18n)
app.use(router)
app.mount('#app')
