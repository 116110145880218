import { createI18n } from 'vue-i18n';
import { en } from './locales/en';
import { ua } from './locales/ua';
import { ru } from "@/locales/ru";
import { pl } from "@/locales/pl";

export default createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  globalInjection: true,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: { en, ua, ru, pl }
});
