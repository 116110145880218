<template>
  <teleport to=".page">
    <div class="notification" ref="element" :id="`notification-${id}`">
      <p class="notification__text">{{ text }}</p>
    </div>
  </teleport>
</template>

<script lang='ts' setup>
import { onBeforeUnmount, onMounted, ref } from "vue";

defineProps({
  text: String,
});

const id = document.getElementsByClassName('notification').length;
const element = ref();
const timer = ref();

const removePopup = () => {
  clearTimeout(timer.value);
  element.value && element.value?.parentNode?.removeChild(element.value);
};

onMounted(() => {
  timer.value = setTimeout(removePopup, 3500);
  document.addEventListener('click', removePopup);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', removePopup);
  clearTimeout(timer.value);
});
</script>

<style lang='scss' scoped>
.notification {
  @extend .row-container;
  justify-content: center;
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: max-content;
  height: max-content;
  padding: 0.75rem 1rem;
  margin: auto;
  background-color: white;
  backdrop-filter: blur(4px);
  border-radius: 10px;
  box-shadow: 0 0 4px #0003;
  animation: notificationAnimation 3.5s;
  z-index: 1000;

  &__text {
    @include font-18();
    text-wrap: balance;
  }
}

.desk .notification {
  padding: 1.5rem 3.75rem;
  box-sizing: border-box;

  &__text {
    @include font-20();
  }
}

.mobile .notification {
  &__text {
    max-width: 80vw;
  }
}

@keyframes notificationAnimation {
  0% {
    opacity: 0;
    scale: 0.5;
  }
  15% {
    opacity: 1;
    scale: 1;
  }
  80% {
    opacity: 1;
    scale: 1;
  }
  100% {
    opacity: 0;
    scale: 0.5;
  }
}
</style>
